@import '../variables';
@import '../placeholders';

.container {
    @extend %fullscreen;

    display: flex;
    padding: 10px;
    background-color: rgba($color-white, 0.5);
    opacity: 0;
    pointer-events: none;
    transition: opacity 0.25s ease;
    color: $color-white;

    .icon {
        margin: auto;
        width: 80%;
        max-width: 500px;
        height: auto;
    }

    &.loading {
        opacity: 1;
        pointer-events: auto;
    }
}