@import '../variables';
@import '../placeholders';

.container {
    position: relative;
    display: flex;
    align-items: stretch;

    .toggler {
        display: inline-flex;
        justify-content: center;
        width: 50px;
        align-items: center;
        padding: 0 10px;

        img {
            width: 20px;
        }
    }

    .label {
        margin: 0 0.5em;
        white-space: nowrap;
    }

    .popupContainer {
        position: absolute;
        top: 100%;
        right: 0;
        transform: translateX(100%);
        transition: transform 0.2s ease;
    }

    .popup {
        position: relative;
        padding: 10px;
        background-color: rgba($color-white, 0.95);
        color: $color-black;
    }

    .content {
        opacity: 0;
        transition: opacity 0.2s ease;
    }

    .contributionsTypes,
    .tronconsTypes {
        button {
            display: flex;
            align-items: center;
            white-space: nowrap;

            .checkbox {
                width: 10px;
            }

            .icon {
                width: 20px;
                height: 25px;
                margin-left: 5px;
            }

            .label {
                font-size: 12px;
            }

            // &.selected {

            // }
        }
    }

    // .section {

    // }

    .tronconsTypes {
        border-top: 1px solid rgba($color-black, 0.5);
        border-bottom: 1px solid rgba($color-black, 0.5);
        padding: 10px 0;
        margin: 10px 0;
    }

    .summerSection {
        border-top: 1px solid rgba($color-black, 0.5);
        padding: 10px 0;
        margin-top: 10px;
    }

    .tronconsStatus {
        font-size: 14px;
        .title {
            font-weight: bold;
        }

        .note {
            text-align: right;
            font-size: 10px;
        }
    }

    .tronconStates {
        margin: 10px 0;
    }

    .tronconState {
        display: flex;
        align-items: center;
        padding: 5px 0;
        font-size: 12px;

        .line {
            transform: rotate(-10deg);
            width: 30px;
            height: 5px;
            border-radius: 5px;
        }

        .label {
            margin-left: 10px;
        }
    }

    // .customMapLayer {
        
    // }

    &.opened {
        .popupContainer {
            transform: translateX(0);
        }

        .content {
            opacity: 1;
        }
    }
}
