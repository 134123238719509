@import '../variables';
@import '../placeholders';

.container {
    position: relative;
    display: flex;
    align-items: stretch;

    .dateRangeInner {
        display: inline-flex;
        align-items: center;
        padding: 0 10px;
    }

    .label {
        margin: 0 0.5em;
        white-space: nowrap;
    }

    .popupSafe {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba($color-black, 0.5);
        cursor: pointer;
        opacity: 0;
        pointer-events: none;
        transition: opacity 0.2s ease;
    }

    .popupContainer {
        position: absolute;
        top: 100%;
        left: 50%;
        transform: translateX(-50%);
        pointer-events: none;
    }

    .popup {
        transform: scale(0);
        transform-origin: top center;
        transition: transform 0.2s ease;
    }

    .arrow {
        position: relative;
        top: 3px;
        text-align: center;

        &::after {
            display: inline-block;
            content: '';
            width: 0;
            height: 0;
            border-left: 20px solid transparent;
            border-right: 20px solid transparent;
            border-bottom: 20px solid $color-blue-background;
        }
    }

    .content {
        position: relative;
        overflow: hidden;
        background-color: $color-blue-background;
        border-radius: 20px;
    }

    .choices {
        opacity: 0;
        transition: opacity 0.2s ease;

        button {
            min-width: 240px;
            width: 100%;
            padding: 10px 12px;
            font-size: 14px;
            border-bottom: 1px solid rgba($color-white, 0.1);

            &:first-child {
                padding-top: 12px;
            }

            &:last-child {
                padding-bottom: 12px;
                border-bottom: none;
            }

            &:hover {
                background-color: rgba($color-white, 0.05);
            }

            &.selected {
                background-color: rgba($color-white, 0.15);
            }
        }
    }

    &.opened {
        .popupSafe {
            // opacity: 1;
            pointer-events: auto;
        }

        .popupContainer {
            pointer-events: auto;
        }

        .popup {
            transform: scale(1);
        }

        .choices {
            opacity: 1;
            transition-delay: 0.15s;
        }
    }
}
