@import '../variables';
@import '../placeholders';

.container {
    padding-bottom: 15px;

    .label, legend {
        font-size: 14px;
    }

    .label {        
        padding-bottom: 10px;
    }

    .fieldsetContent {
        padding: 10px;
    }
}