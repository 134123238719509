@import '../variables';
@import '../placeholders';

.container {
    

    .inner {
        position: relative;
        padding: 8px;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .background {
        @extend %fullscreen;
    }

    .upload {
        position: relative;
        width: 80px;
        height: 80px;
        margin-bottom: 20px;
        text-align: center;
        color: $color-white;
    }

    .uploadContent {
        @extend %fullscreen;

        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;  
        padding: 10px;
    }

    .icon {
        font-size: 25px;
    }

    .label {
        font-size: 12px;
        font-weight: bold;
        margin-top: 10px;
    }
}