@import '../variables';
@import '../placeholders';

.container {
    @extend %fullscreen;

    display: flex;
    flex-direction: column;

    .mapHeader {
        z-index: 1;
        position: relative;
        display: flex;
        align-items: stretch;
        height: 50px;
        color: $color-white;
        background-color: $color-blue-background;

        & > * {
            display: flex;
            flex-direction: column;
            justify-content: center;
            flex: 1 1 0;

            & > * {
                flex-grow: 1;
            }
        }

        .left {
            align-items: flex-start;
        }

        .center {
            align-items: center;
        }

        .right {
            align-items: flex-end;
        }

        .menuButton {
            width: 50px;
        }

        .timeFilter {
            z-index: 1;
        }

        .layersFilter {
            z-index: 0;
        }
    }

    .mapContainer {
        z-index: 0;
        position: relative;
        flex-grow: 1;
    }

    .map {
        @extend %fullscreen;
    }

    .mapMarkerContainer {
        position: absolute;
        bottom: 50%;
        left: 50%;
        transform: translateY(-50vh);
        transition: transform .2s ease-in;
    }

    .mapMarker {
        position: absolute;
        bottom: 0;
        transform: translateX(-50%);
    }

    &.mapMarkerDropped {
        .mapMarkerContainer {
            transform: translateY(0vh);
        }
    }

    .addContributionButton {
        position: absolute;
        bottom: 0;
        right: 0;
        max-width: 100%;
        padding: 10px;
        z-index: 2;
        opacity: 1;
        transition: opacity 0.25s ease;
    }

    .reportLinksButton {
        position: absolute;
        bottom: 10px;
        left: 10px;
        z-index: 2;
        opacity: 1;
        transition: opacity 0.25s ease;
    }

    .homeMenu {
        @extend %fullscreen;

        z-index: 2;
        max-width: 480px;
        transform: translateX(-100%);
        transition: transform 0.25s ease;
    }

    .menuButton {
        position: absolute;
        top: 0;
        left: 0;
        width: 50px;
        height: 50px;

        z-index: 3;
    }

    .addContribution {
        @extend %fullscreen;

        z-index: 2;
        transform: translateY(100%);
        transition: transform 0.25s ease;
    }

    .contributionDetailsContainer {
        @extend %fullscreen;

        z-index: 2;
        pointer-events: none;
        overflow: auto;
        opacity: 0;
        transition: opacity 0.25s ease;

        background-color: rgba($color-black, 0.5);
    }

    .contributionDetails {
        display: flex;
        position: relative;
        min-height: 100%;
        padding: 20px;
        height: auto;

        @media only screen and (min-width: 800px) {
            padding: 60px 0;
        }
    }

    .contributionDetailsSafe {
        position: absolute;
        top: 0;
        left: 0;
        bottom: auto;
        width: 100%;
        min-height: 100%;

        cursor: pointer;
    }

    .contributionDetailsGroup {
        position: relative;
        margin: auto;

        width: 100%;
        max-width: 600px;

        transform: scale(0);
        transition: transform 0.25s ease;
    }

    .contributionDetailsInner {
        position: relative;
    }

    &.loading {        
        .reportLinksButton,
        .addContributionButton {
            opacity: 0.5;
            pointer-events: none;
        }
    }

    &.menuOpened {
        .homeMenu {
            transform: translateX(0);
        }

        .menuButton {
            color: $color-black;
        }
    }

    &.contributionSelected {
        .contributionDetailsContainer {
            pointer-events: auto;
            opacity: 1;
        }

        .contributionDetailsGroup {
            transform: scale(1);
        }
    }

    &.confirmationOpened {
        .addContributionButton {
            pointer-events: none;
        }
        
        .addContribution {
            transform: translateY(0);
        }
    }

    &.contributionSubmited {
        .addContribution {
            transform: translateY(-100%);
            transition-timing-function: ease-in;
        }
    }

    &.addContribution {
        .reportLinksButton {
            opacity: 0;
            pointer-events: none;
        }
    }

    &.reportLinksOpened {
        .addContributionButton {
            opacity: 0;
            pointer-events: none;
        }
    }
}
