@import '../variables';
@import '../placeholders';

.container {
    @extend %fullscreen;

    overflow: hidden;

    .main {
        @extend %fullscreen;
    }
}