@import '../variables';
@import '../placeholders';

.container {
    padding: 20px;
    background-color: $color-white;

    .close {
        position: absolute;
        top: 10px;
        right: 10px;
        color: $color-gray;
    }

    // .content {

    // }

    .contributionType {
        display: flex;
        align-items: center;

        margin: -20px -20px 10px;
        padding: 10px 20px;

        .icon {
            display: inline-block;
            width: 30px;
            margin-right: 10px;
        }

        .labelContainer {
            color: $color-white;
        }

        .label {
            font-size: 20px;
            font-weight: bold;
        }

        .qualityLabel {
            font-size: 14px;
        }
    }

    .dates {
        font-size: 14px;
        margin-bottom: 20px;
    }

    // .createdDate {

    // }

    .updatedDate {
        margin-top: 2px;
        font-weight: bold;
    }

    .comment {        
        white-space: pre-line;
        margin-bottom: 20px;

        .body {
            text-wrap: pretty;
        }

        h1, h2, h3, h4, h5, h6, b, em {
            font-weight: bold;
        }

        i {
            font-style: italic;
        }
    }

    // .media {

    // }

    .photo {
        width: 100%;
        height: auto;
    }

    .videoContainer {
        position: relative;
        height: 0;
        padding-bottom: 56.25%;
    }

    .video {
        @extend %fullscreen;
    }

    .voteContainer {
        display: flex;
        align-items: center;
        margin: 30px -2px;

        .voteButtonContainer {
            flex: 1 1 0;
            padding: 0 2px;
        }

        button {
            width: 100%;
            padding: 10px 2px;
            border-radius: 5px;
            color: $color-white;
            font-size: 11px;

            &.positive {
                background-color: $color-green;
            }

            &.negative {
                background-color: $color-orange;
            }
        }
    }

    .repliesCount {
        display: flex;
        align-items: center;
        margin: 20px 0;
        color: $color-gray;

        span {
            margin-left: 5px;
            font-weight: bold;
        }

        &.hasComments {
            color: $color-blue;
        }
    }

    // .replies {

    // }

    .reply {
        margin-bottom: 20px;
    }

    pointer-events: none;

    &.ready {
        pointer-events: auto;
    }

    &.voteDisabled {
        .voteContainer {
            opacity: 0.5;
            pointer-events: none;
        }
    }

    @media screen and (min-width: 360px) {
        .voteContainer {
            margin: 30px -5px;

            .voteButtonContainer {
                padding: 0 5px;
            }

            button {
                padding: 10px 5px;
                font-size: 14px;
            }
        }
    }

    @media screen and (min-width: 450px) {
        .contributionType {
            .label {
                display: inline-block;
            }

            .qualityLabel {
                display: inline-block;
                font-size: 16px;

                &:before {
                    content: '-';
                    display: inline-block;
                    margin: 0 0.5em;
                }
            }
        }
    }
}
