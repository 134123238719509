@import './vendor/openlayers';
@import './vendor/google-recaptcha-v3';
@import './reset';
@import './variables';
@import './placeholders';

* {
    -webkit-tap-highlight-color: transparent;
}

html, body, #root {
    height: 100%;
}

body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
        'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

button {
    appearance: none;
    border: 0;
    padding: 0;
    margin: 0;
    background: transparent;
    color: inherit;
    font: inherit;
    cursor: pointer;

    &:disabled {
        opacity: 0.5;
        pointer-events: none;
    }
}

input, textarea {
    font: inherit;
    background-color: #fff;
    border: 1px solid #a5a5a5;
    border-radius: 5px;
}

form {
    input, textarea {
        appearance: none;
    }
}

fieldset {
    border: 1px solid #a5a5a5;
    border-radius: 5px;
}

legend {
    padding: 0 10px;
}