:root,
:host {
    --ol-background-color: white;
    --ol-accent-background-color: #f5f5f5;
    --ol-subtle-background-color: rgba(128, 128, 128, 0.25);
    --ol-partial-background-color: rgba(255, 255, 255, 0.75);
    --ol-foreground-color: #333333;
    --ol-subtle-foreground-color: #666666;
    --ol-brand-color: #00aaff;
}

.ol-box {
    box-sizing: border-box;
    border-radius: 2px;
    border: 1.5px solid var(--ol-background-color);
    background-color: var(--ol-partial-background-color);
}

.ol-mouse-position {
    top: 8px;
    right: 8px;
    position: absolute;
}

.ol-scale-line {
    background: var(--ol-partial-background-color);
    border-radius: 4px;
    bottom: 8px;
    left: 8px;
    padding: 2px;
    position: absolute;
}

.ol-scale-line-inner {
    border: 1px solid var(--ol-subtle-foreground-color);
    border-top: none;
    color: var(--ol-foreground-color);
    font-size: 10px;
    text-align: center;
    margin: 1px;
    will-change: contents, width;
    transition: all 0.25s;
}

.ol-scale-bar {
    position: absolute;
    bottom: 8px;
    left: 8px;
}

.ol-scale-bar-inner {
    display: flex;
}

.ol-scale-step-marker {
    width: 1px;
    height: 15px;
    background-color: var(--ol-foreground-color);
    float: right;
    z-index: 10;
}

.ol-scale-step-text {
    position: absolute;
    bottom: -5px;
    font-size: 10px;
    z-index: 11;
    color: var(--ol-foreground-color);
    text-shadow: -1.5px 0 var(--ol-partial-background-color),
        0 1.5px var(--ol-partial-background-color), 1.5px 0 var(--ol-partial-background-color),
        0 -1.5px var(--ol-partial-background-color);
}

.ol-scale-text {
    position: absolute;
    font-size: 12px;
    text-align: center;
    bottom: 25px;
    color: var(--ol-foreground-color);
    text-shadow: -1.5px 0 var(--ol-partial-background-color),
        0 1.5px var(--ol-partial-background-color), 1.5px 0 var(--ol-partial-background-color),
        0 -1.5px var(--ol-partial-background-color);
}

.ol-scale-singlebar {
    position: relative;
    height: 10px;
    z-index: 9;
    box-sizing: border-box;
    border: 1px solid var(--ol-foreground-color);
}

.ol-scale-singlebar-even {
    background-color: var(--ol-subtle-foreground-color);
}

.ol-scale-singlebar-odd {
    background-color: var(--ol-background-color);
}

.ol-unsupported {
    display: none;
}

.ol-viewport,
.ol-unselectable {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
    -webkit-tap-highlight-color: transparent;
}

.ol-viewport canvas {
    all: unset;
}

.ol-selectable {
    -webkit-touch-callout: default;
    -webkit-user-select: text;
    -moz-user-select: text;
    user-select: text;
}

.ol-grabbing {
    cursor: -webkit-grabbing;
    cursor: -moz-grabbing;
    cursor: grabbing;
}

.ol-grab {
    cursor: move;
    cursor: -webkit-grab;
    cursor: -moz-grab;
    cursor: grab;
}

.ol-control {
    position: absolute;
    background-color: var(--ol-subtle-background-color);
    border-radius: 4px;
}

.ol-zoom {
    top: 10px;
    left: 10px;
}

.ol-rotate {
    top: 80px;
    left: 10px;
    transition: opacity 0.25s linear, visibility 0s linear;
}

.ol-rotate.ol-hidden {
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.25s linear, visibility 0s linear 0.25s;
}

.ol-zoom-extent {
    top: 140px;
    left: 10px;
}

.ol-full-screen {
    right: 10px;
    top: 10px;
}

.ol-control button {
    display: block;
    margin: 1px;
    padding: 0;
    color: var(--ol-subtle-foreground-color);
    font-weight: bold;
    text-decoration: none;
    font-size: inherit;
    text-align: center;
    height: 1.375em;
    width: 1.375em;
    line-height: 0.4em;
    background-color: var(--ol-background-color);
    border: none;
    border-radius: 2px;
}

.ol-control button::-moz-focus-inner {
    border: none;
    padding: 0;
}

.ol-zoom-extent button {
    line-height: 1.4em;
}

.ol-compass {
    display: block;
    font-weight: normal;
    will-change: transform;
}

.ol-touch .ol-control button {
    font-size: 1.5em;
}

.ol-touch .ol-zoom-extent {
    top: 5.5em;
}

.ol-control button:hover,
.ol-control button:focus {
    text-decoration: none;
    outline: 1px solid var(--ol-subtle-foreground-color);
    color: var(--ol-foreground-color);
}

.ol-zoom .ol-zoom-in {
    border-radius: 2px 2px 0 0;
}

.ol-zoom .ol-zoom-out {
    border-radius: 0 0 2px 2px;
}

.ol-attribution {
    text-align: left;
    left: 0.5em;
    bottom: 0.5em;
    max-width: calc(100% - 1.3em);
    display: flex;
    // flex-flow: row-reverse;
    align-items: center;
    visibility: hidden;
}

.ol-attribution a {
    color: var(--ol-subtle-foreground-color);
    text-decoration: none;
}

.ol-attribution ul {
    margin: 0;
    padding: 1px 0.5em;
    color: var(--ol-foreground-color);
    text-shadow: 0 0 2px var(--ol-background-color);
    font-size: 12px;
}

.ol-attribution li {
    display: inline;
    list-style: none;
}

.ol-attribution li:not(:last-child):after {
    content: ' ';
}

.ol-attribution img {
    max-height: 2em;
    max-width: inherit;
    vertical-align: middle;
}

.ol-attribution button {
    flex-shrink: 0;
}

.ol-attribution.ol-collapsed ul {
    display: none;
}

.ol-attribution:not(.ol-collapsed) {
    background: var(--ol-partial-background-color);
}

.ol-attribution.ol-uncollapsible {
    bottom: 0;
    left: 0;
    border-radius: 4px 0 0;
}

.ol-attribution.ol-uncollapsible img {
    margin-top: -0.2em;
    max-height: 1.6em;
}

.ol-attribution.ol-uncollapsible button {
    display: none;
}

.ol-zoomslider {
    top: 4.5em;
    left: 0.5em;
    height: 200px;
}

.ol-zoomslider button {
    position: relative;
    height: 10px;
}

.ol-touch .ol-zoomslider {
    top: 5.5em;
}

.ol-overviewmap {
    left: 0.5em;
    bottom: 0.5em;
}

.ol-overviewmap.ol-uncollapsible {
    bottom: 0;
    left: 0;
    border-radius: 0 4px 0 0;
}

.ol-overviewmap .ol-overviewmap-map,
.ol-overviewmap button {
    display: block;
}

.ol-overviewmap .ol-overviewmap-map {
    border: 1px solid var(--ol-subtle-foreground-color);
    height: 150px;
    width: 150px;
}

.ol-overviewmap:not(.ol-collapsed) button {
    bottom: 0;
    left: 0;
    position: absolute;
}

.ol-overviewmap.ol-collapsed .ol-overviewmap-map,
.ol-overviewmap.ol-uncollapsible button {
    display: none;
}

.ol-overviewmap:not(.ol-collapsed) {
    background: var(--ol-subtle-background-color);
}

.ol-overviewmap-box {
    border: 1.5px dotted var(--ol-subtle-foreground-color);
}

.ol-overviewmap .ol-overviewmap-box:hover {
    cursor: move;
}
