@import '../variables';
@import '../placeholders';

.container {
    .date {
        font-size: 14px;
    }

    // .name {

    // }

    .message {
        margin-top: 5px;
    }
}