@import '../variables';
@import '../placeholders';

.container {
    display: block;
    position: relative;
    border-radius: 5px;
    border: 1px dashed rgba(#fff, 0.5);
    overflow: hidden;
    cursor: pointer;
        transition: background-color .2s ease, border .2s ease;

     @media (hover: hover) {
        &:hover {
            background-color: rgba(#fff, 0.1);
            border-color: #fff;
        }
    }

    .content {
        @extend %fullscreen;
    }

    img {
        @extend %fullscreen;

        object-fit: cover;
        visibility: hidden;
    }

    input {
        @extend %fullscreen;

        opacity: 0;
        cursor: pointer;
    }

    .close {
        position: absolute;
        top: 2px;
        right: 2px;
        width: 25px;
        height: 25px;
        background-color: $color-white;
        border-radius: 50%;
        color: $color-black;
        visibility: hidden;
    }

    &.hasFile {
        border-color: transparent;

        img {
            visibility: visible;
        }

        .content {
            visibility: hidden;
        }

        .close {
            visibility: visible;
        }
    }
}