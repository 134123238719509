@import '../variables';
@import '../placeholders';

.container {
    position: relative;
    background-color: white;
    display: flex;
    flex-direction: column;

    .closeButton {
        position: absolute;
        top: 0;
        left: 0;
        padding: 10px;
        color: $color-gray;
    }

    .localeSelector {
        position: absolute;
        top: 0;
        right: 0;
        padding: 15px 5px;
    }

    .content {
        padding: 60px 20px 20px;
        display: flex;
        flex-direction: column;
        flex-grow: 1;
        overflow: auto;
    }

    .title {
        font-size: 32px;
        font-weight: bold;
    }

    .description {
        margin-top: 20px;
        padding: 20px 0;
        border-top: 1px solid rgba($color-black, 0.4);
        line-height: 2;
        white-space: pre-line;
    }

    .footer {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-top: auto;
        padding-top: 20px;
        border-top: 1px solid rgba($color-black, 0.4);

        a {
            color: inherit;
            text-decoration: none;
        }
    }
}