@import '../variables';
@import '../placeholders';

.container {
    display: flex;
    flex-direction: column;

    .form {
        flex-grow: 1;
        display: flex;
        flex-direction: column;
        transition: opacity 0.2s ease;
    }

    // .content {

    // }

    .name,
    .comment {
        padding-bottom: 10px;

        input, textarea {
            display: block;
            width: 100%;
            padding: 10px;
            font-size: 18px;
        }

        textarea {
            height: 100px;
        }
    }

    .photo {
        margin-bottom: 20px;
    }

    // .captcha {
        
    // }

    .errors {
        text-align: right;
        margin-top: auto;
        color: red;
        min-height: 1em;
    }

    .actions {
        display: flex;
        align-items: center;
        margin-top: auto;
        padding-top: 20px;

        button {
            color: $color-white;
            background-color: $color-blue;
            padding: 10px 15px;
            border-radius: 5px;
        }

        .submitButton {
            margin-left: auto;
        }
    }

    &.loading {
        .form {
            opacity: 0.5;
            pointer-events: none;
        }
    }
}
