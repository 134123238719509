@import '../variables';
@import '../placeholders';

.container {
    position: relative;

    .links {
        position: absolute;
        left: 0;
        bottom: 100%;
        display: flex;
        flex-direction: column;
        align-items: stretch;
        opacity: 0;
        transition: opacity 0.25s ease;
        pointer-events: none;
    }

    .linkContainer {
        position: relative;

        &:last-child {
            .linkContent {
                padding-bottom: 0;
            }
        }
    }

    .linkContent {
        padding-bottom: 5px;
        opacity: 0;
        transform: translateY(10px);
        transition: opacity 0.3s ease-in, transform 0.25s ease;
    }

    .link {
        position: relative;
        overflow: hidden;
        display: flex;
        width: 100%;
        align-items: center;
        color: inherit;
        background-color: $color-white;
        border-width: 2px;
        border-style: solid;
        border-radius: 10px;
        text-decoration: none;
        cursor: pointer;
    }

    .iconContainer {
        display: block;
        padding: 6px;
    }

    .icon {
        width: 28px;
        height: 28px;
    }

    .label {
        white-space: nowrap;
        padding: 10px;
        font-size: 12px;
    }

    .chevron {
        margin-left: auto;
        padding-right: 10px;
        font-size: 0.75em;
    }

    .actions {
        position: relative;
        display: flex;
        align-items: center;
        margin-top: 10px;

        button {
            padding: 10px;
            min-width: 100px;
            color: $color-white;
            border-radius: 5px;
            font-size: 14px;
        }
    }

    .toggleOpenButton {
        background-color: $color-blue-background;
    }

    &.opened {
        .links {
            pointer-events: auto;
            opacity: 1;
        }

        .linkContent {
            transform: translateY(0);
            opacity: 1;
        }
    }
}
