@import '../variables';
@import '../placeholders';

.container {
    display: inline-block;
    position: relative;

    .background {
        display: block;
        width: 100%;
        height: 100%;
    }

    .content {
        @extend %fullscreen;
    }

    .center {
        position: relative;
        padding: 15%;

        img {
            display: block;
            width: 100%;
            height: 100%;
        }
    }

    &.withoutMarker {
        .center {
            padding: 0;
        }
    }
}